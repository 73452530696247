import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import ScrollToTop from "../ScrollToTop";
import AnimatedCursor from "react-animated-cursor";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../header/Header";
import Footer from "../footer/Footer";

export default function Layout({ children }) {
  useEffect(() => {
    AOS.init();
  }, []);

  const { sanitySitesettings } = useStaticQuery(
    graphql`
      query {
        sanitySitesettings {
          theme
          header {
            logo {
              height
              width
              alt
              image {
                asset {
                  gatsbyImageData(
                    fit: FILLMAX
                    placeholder: BLURRED
                    formats: WEBP
                  )
                }
              }
            }
            logo2 {
              height
              width
              alt
              image {
                asset {
                  gatsbyImageData(
                    fit: FILLMAX
                    placeholder: BLURRED
                    formats: WEBP
                  )
                }
              }
            }
            menu {
              label
              path
              svg
            }
          }
          cursor {
            enable
            color {
              rgb {
                r
                g
                b
              }
            }
          }

          _rawCc
        }
      }
    `
  );
  const rgb = sanitySitesettings?.cursor?.color?.rgb;

  return (
    <div className="civsav_tm_all_wrap" id="root">
      <Header data={sanitySitesettings} />
      {sanitySitesettings?.cursor && sanitySitesettings.cursor?.enable && (
        <AnimatedCursor
          innerSize={8}
          outerSize={44}
          color={`${rgb?.r}, ${rgb?.g}, ${rgb?.b}`}
          outerAlpha={0.3}
          innerScale={0.7}
          outerScale={1.2}
        />
      )}
      <ScrollToTop />
      <main>{children}</main>
      <div className="civsav_tm_copyright">
        <div className="container">
          <Footer data={sanitySitesettings?._rawCc} />
        </div>
      </div>
    </div>
  );
}
