import React, { useState, useEffect } from "react";
import Scrollspy from "react-scrollspy";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Header = ({ data }) => {
  const [navbar, setNavbar] = useState(false);
  const [list, setList] = useState([]);
  const header = data?.header;

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    if (data?.header) {
      const labels = data?.header?.menu.map((item) => item.path);
      setList(labels);
    }
    window.addEventListener("scroll", changeBackground);

    return () => window.removeEventListener("scroll", changeBackground);
  }, []);

  if (list.length === 0) return null;
  return (
    <>
      {/* TOPBAR  */}
      <div className={navbar ? "civsav_tm_topbar animate" : "civsav_tm_topbar"}>
        <div className="in">
          <div className="topbar_inner">
            <div className="logo">
              <Link to="/">
                {data?.theme ? (
                  <div
                    style={{
                      height: header?.logo?.height,
                      width: header?.logo?.width,
                    }}
                  >
                    <GatsbyImage
                      image={header.logo?.image?.asset?.gatsbyImageData}
                      alt={header.logo.alt}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      height: header?.logo2?.height,
                      width: header?.logo2?.width,
                    }}
                  >
                    <GatsbyImage
                      image={header.logo2?.image?.asset?.gatsbyImageData}
                      alt={header.logo2.alt}
                    />
                  </div>
                )}
              </Link>
            </div>
            <div className="menu">
              <Scrollspy
                className="anchor_nav"
                items={list}
                currentClassName="current"
                offset={-88}
              >
                {data?.header?.menu.map((item, index) => (
                  <li className={`${index === 0 ? "current" : ""}`} key={index}>
                    <a href={`#${item.path}`}>
                      <span className="first">{item.label}</span>
                      <span className="second">{item.label}</span>
                    </a>
                  </li>
                ))}
              </Scrollspy>
            </div>
          </div>
        </div>
      </div>
      {/* /TOPBAR */}

      <div className="mobile-menu-wrapper">
        <Scrollspy
          className="mobile_menu-icon"
          items={list}
          currentClassName="current"
          offset={-65}
        >
          {data?.header?.menu.map((item, index) => (
            <li className={`${index === 0 ? "current" : ""}`} key={index}>
              <a href={`#${item.path}`}>
                <div
                  dangerouslySetInnerHTML={{ __html: item.svg }}
                  className="flex justify-center"
                />
                <span>{item.label}</span>
              </a>
            </li>
          ))}
        </Scrollspy>
      </div>
      {/* End mobile-menu */}
    </>
  );
};

export default Header;
