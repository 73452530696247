import React from "react";
import { PortableText } from "@portabletext/react";

const Footer = ({ data }) => {
  return (
    <>
      <div className="inner">
        <div className="copy">{data && <PortableText value={data} />}</div>
      </div>
    </>
  );
};

export default Footer;
